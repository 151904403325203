<template>
	<div>
		<Header ref="Header" />
		<img :src="menuPic" class="Big-title" />
		<div class="container">
			<div class="personal-list row">
				<div class="personal-list-left">
					<div class="index-sort-tiltle personal-sort-tiltle">个人中心</div>
					<div class="index-sort-list">
						<div v-for="(item,index) in nav" :key="index">
							<div @click="sortSwitch(index)" :class="sortIndex==index?'index-sort-item2':'index-sort-item'">
								{{item.name}}
							</div>
							<img class="index-sort-boder" v-if="sortIndex==index" src="../../public/images/boder_01.png" />
						</div>
					</div>
				</div>
				<div class="personal-list-right">
					<div class="personal-right-order">
						<div class="personal-order-nav flex">
							<div v-for="(item,index) in orderNav" :key="index">
								<div @click="oderNavSwitch(index)" class="jump"
									:class="oderNavIndex==index?'personal-order-nav-item1':'personal-order-nav-item'">
									{{item.name}}
								</div>
							</div>
						</div>
						<div class="personal-order-list">
							<div v-for="(item,index) in order" :key="index">
								<div class="personal-order-item row">
									<img @click="pageTo('introduction',item.placeId)" class="personal-order-pic jump" :src="item.placePicture" />
									<div class="personal-order-item-right row" >
										<div class="personal-order-midle">
											<div @click="pageTo('introduction',item.placeId)" class="personal-order-name jump">{{item.placeName}}
											</div>
											<div class="personal-order-place row">
												<img class="personal-order-loc" src="../../public/images/place_01.png">
												<div class="personal-order-address">{{item.address}}</div>
											</div>
											<div class="personal-order-time">{{item.createTime}}</div>
										</div>
										<div class="personal-order-right">
											<div class="personal-order-price row">
												<div class="personal-order-num">￥{{item.placePrice}}</div>
												<div class="personal-order-qi">{{item.placePriceUnit}}</div>
											</div>
											<div class="personal-order-btn1" v-show="item.status>0 && item.status<4">待确认</div>
											<div class="personal-order-btn2" v-show="item.status==4">已完成</div>
											<div class="personal-order-btn3" v-show="item.status==5 || item.status==6">已取消</div>
										</div>
									</div>
								</div>
							</div>
							<div v-if="order.length==0" class="mypublish-tips">
								暂无数据哦~
							</div>
							<el-pagination
								style="margin-top: 50px;"
								class="bar-switch"
								background
								layout="prev, pager, next, jumper, total"
								:page-size="postData.pageSize"
								:total="Total"
								@current-change="choosePage">
							</el-pagination>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import Header from '@/components/header.vue';
	import {
		mapState
	} from 'vuex';
	export default {
		components: {
			Header
		},
		data() {
			return {
				Total:0,
				menuPic:'',
				oderNavIndex: 0,
				sortIndex: 2,
				postData:{
					pageNum:1,
					pageSize:10,
				},
				nav:[
					{name:'个人信息',href:'personal'},
					{name:'发布需求',href:'publish'},
					{name:'我的订单',href:'myOrder'},
					{name:'我的发布',href:'myPublish'},
					{name:'我的收藏',href:'myCollect'},
					{name:'我的消息',href:'myNews'},
					{name:'意见反馈',href:'feedback'},
				],
				orderNav: [
					{name:'全部订单',status:[0]},
					{name:'待确认',status:[1,2,3]},
					{name:'已完成',status:[4]},
					{name:'已取消',status:[5,6]},
				],
				order: [],
			}
		},
		computed: {
			...mapState(['userInfo']),
		},
		mounted() {
			this.$refs.Header.setIndex(-1);
			// 获取菜单图片
			this.$api.getMenuPic('发布需求').then(res=>{
				console.log(res,'菜单图片');
				this.menuPic=res.data[0].picture;
			})
			// 获取订单列表
			this.getOrderLsit();
		},
		methods: {
			sortSwitch(index){
				this.sortIndex=index;
				this.$router.push({path:this.nav[index].href});
			},
			oderNavSwitch(index) {
				this.oderNavIndex = index;
				if(index==0){
					delete this.postData.status;
				}else{
					this.postData.status=this.orderNav[index].status.toString();
				}
				this.getOrderLsit();
			},
			// 选择分页
			choosePage(num){
				this.postData.pageNum=num;
				this.getOrderLsit();
			},
			getOrderLsit(){
				console.log(this.postData);
				this.$api.myOrders(this.postData).then(res=>{
					console.log(res,'订单列表');
					this.order=res.rows;
					this.Total=parseInt(res.total);
				})
			},
			pageTo(page,id){
				this.$router.push({
					path:page,
					query: {
						id:id,
					}
				})
			}
		},
	};
</script>
<style scoped>
</style>
